<template>
  <div>
    <b-card>
      <shop-preset v-model="form.shop"></shop-preset>
      <small>log 수</small><br/>
      <b-input v-model="limit" class="w-100px"></b-input>
      <b-button class="mt-1" variant="primary" @click="getStat" :disabled="busy.getStat">통계<b-spinner class="ml-1" small v-if="busy.getStat"></b-spinner></b-button>
    </b-card>

    <b-row>
      <b-col v-for="s of stats" cols="12" :key="s.shop_id">
        <b-card>
          <h3>{{s.shop_id}}. {{s.shop.boutique}} <b-badge v-if="s.shop.use_yn !== 'y'" variant="danger">미사용</b-badge></h3>
          <h5>logFetch</h5>
          <b-row>
            <b-col cols="4">
              <div v-for="f of s.logFetch">
                <b-badge variant="secondary">{{f._dt}}</b-badge> ~
                <b-badge variant="secondary">{{f._fin_dt || '미완'}}</b-badge>
                <b-badge variant="light">{{f._query}}</b-badge>
                <b-badge v-if="f.elapsed" variant="success">elapsed: {{f.elapsed}}</b-badge>
                <div v-if="f.elapsed" class="p-2 mb-2 b-b-1">
                  <template v-if="f._src && f._src.code === 304">
                    <b-badge variant="warning">304 Not Modified</b-badge> 로 Skip
                  </template>
                  <template v-else>
                    <b-badge variant="success">confirmed</b-badge> {{f.confirmed_all}} 중 {{f.confirmed}} 개 업데이트<br/>
                    <template v-if="f.mapped">
                      <b-badge variant="info">mapped</b-badge>
                      &nbsp;<b>{{f.mapped.exists}} / {{f.mapped.count}}</b>, 변경: {{f.mapped.modified}} 개, 추가: {{f.mapped.insert}} 개, 미발견: {{f.mapped.notFound}} 개<br/>
                    </template>
                    <template v-if="f.mapped">
                      <b-badge variant="light">src</b-badge>
                      &nbsp;<b>{{f.src.exists}} / {{f.src.count}}</b>, 중복제거 후 {{f.src.distinctCount}} 개, 변경: {{f.src.modified}} 개, 추가: {{f.src.insert}} 개<br/>
                    </template>
                  </template>
                </div>
              </div>
              <b-button class="mt-1 mr-1" variant="info" @click="$modal.show({type: 'json', item: s.logMapped})">logMapped</b-button>
              <b-button class="mt-1 mr-1" variant="light" @click="$modal.show({type: 'json', item: s.logSrc})">logSrc</b-button>
            </b-col>
            <b-col>
              <div v-for="(e, idx) of s.laps">
                <b-badge v-b-toggle="`${s.shop_id}_${idx}`" variant="primary" class="pointer mr-2"><i class="fa fa-chevron-down"></i></b-badge>
                <b-badge variant="secondary">{{e._dt}}</b-badge> ~
                <b-badge variant="secondary">{{e._fin_dt || '미완'}}</b-badge>
                <b-collapse :id="`${s.shop_id}_${idx}`">
                  마지막 요약 기록 : <b-badge variant="secondary">{{e._summary_dt}}</b-badge>
                  <pre v-if="e.summary" v-html="e.summary.join('\n')"></pre>
                </b-collapse>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import shopPreset from '@/views/ShopPreset.vue'

export default {
  name: "ShopStat",
  title: 'Feed Shop 통계',
  components: {shopPreset},
  data() {
    return {
      form: {
        shop: [],
      },
      limit: 10,
      stats: [],
      busy: {getStat: false},
    }
  },
  async created() {
  },
  methods: {
    async getStat() {
      const shop_ids = this.form.shop.map(e => e.shop_id);
      if (shop_ids.length === 0) return alert('shop 을 선택해주세요');
      const body = {shop_ids};

      this.busy.getStat = true;
      const j = await this.$api.postJson('/dev/shopStat', body);
      this.busy.getStat = false;

      if (j) {
        this.stats = j.stats;
        j.stats.forEach(s => {
          s.logFetch = s.logFetch.sort((a, b) => b._t - a._t).slice(0, this.limit);
          s.logMapped = s.logMapped.sort((a, b) => b._t - a._t).slice(0, this.limit);
          s.logSrc = s.logSrc.sort((a, b) => b._t - a._t).slice(0, this.limit);
          s.logFetch.forEach(f => { // 304 찾기 위함
            f._src = s.logSrc.find(src => src._dt === f._dt);
          });
          s.laps = s.laps.sort((a, b) => b._t - a._t).slice(0, this.limit);
        });
      }
    },
  },
}
</script>
